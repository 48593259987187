import { VuetifyIconComponent } from "vuetify/types/services/icons";

const icons = {
  accountCircle: () => import("./account-circle.vue"),
  alert: () => import("./alert.vue"),
  archiveIn: () => import("./archive-in.vue"),
  archiveOut: () => import("./archive-out.vue"),
  arrowDropDown: () => import("./arrow-drop-down.vue"),
  arrowRL: () => import("./arrow-rl.vue"),
  arrowR: () => import("./arrow-r.vue"),
  arrowsExchange: () => import("./arrows-exchange.vue"),
  autoRefill: () => import("./auto-refill.vue"),
  bell: () => import("./bell.vue"),
  book: () => import("./book.vue"),
  calendar: () => import("./calendar.vue"),
  cardCloseRefund: () => import("./card-close-refund.vue"),
  cardDeposit: () => import("./card-deposit.vue"),
  cardIssueFee: () => import("./card-issue-fee.vue"),
  cardWithdraw: () => import("./card-withdraw.vue"),
  celebrate: () => import("./celebrate.vue"),
  checkAll: () => import("./check-all.vue"),
  check: () => import("./check.vue"),
  checkmark: () => import("./checkmark.vue"),
  closeB: () => import("./close-b.vue"),
  close: () => import("./close.vue"),
  copy: () => import("./copy.vue"),
  creditCardB: () => import("./credit-card-b.vue"),
  creditCard: () => import("./credit-card.vue"),
  crown: () => import("./crown.vue"),
  danger: () => import("./danger.vue"),
  declineFee: () => import("./decline-fee.vue"),
  dollar: () => import("./dollar.vue"),
  dotsVertical: () => import("./dots-vertical.vue"),
  earth: () => import("./earth.vue"),
  edit: () => import("./edit.vue"),
  email: () => import("./email.vue"),
  eur: () => import("./eur.vue"),
  exit: () => import("./exit.vue"),
  facebook: () => import("./facebook.vue"),
  filter: () => import("./filter.vue"),
  fire: () => import("./fire.vue"),
  fundsDeposit: () => import("./funds-deposit.vue"),
  fundsExchange: () => import("./funds-exchange.vue"),
  fundsTransfer: () => import("./funds-transfer.vue"),
  home: () => import("./home.vue"),
  hourglass: () => import("./hourglass.vue"),
  incomingFundsTransfer: () => import("./incoming-funds-transfer.vue"),
  intTransactionFee: () => import("./int-transaction-fee.vue"),
  layerGroup: () => import("./layer-group.vue"),
  leadership: () => import("./leadership.vue"),
  link: () => import("./link.vue"),
  lockClock: () => import("./lock-clock.vue"),
  lock: () => import("./lock.vue"),
  magnifyingGlassB: () => import("./magnifying-glass-b.vue"),
  magnifyingGlass: () => import("./magnifying-glass.vue"),
  mail: () => import("./mail.vue"),
  megaphoneLine: () => import("./megaphone-line.vue"),
  menu: () => import("./menu.vue"),
  moneyStack: () => import("./money-stack.vue"),
  money: () => import("./money.vue"),
  moveMenu: () => import("./move-menu.vue"),
  nestClock: () => import("./nest-clock.vue"),
  newspaper: () => import("./newspaper.vue"),
  noConnection: () => import("./no-connection.vue"),
  pauseL: () => import("./pause-l.vue"),
  pause: () => import("./pause.vue"),
  phone: () => import("./phone.vue"),
  photoCamera: () => import("./photo-camera.vue"),
  pieChartFilled: () => import("./pie-chart-filled.vue"),
  pieChart: () => import("./pie-chart.vue"),
  play: () => import("./play.vue"),
  plus: () => import("./plus.vue"),
  power: () => import("./power.vue"),
  questionC: () => import("./question-c.vue"),
  questionS: () => import("./question-s.vue"),
  question: () => import("./question.vue"),
  refresh: () => import("./refresh.vue"),
  reload: () => import("./reload.vue"),
  repeatCircle: () => import("./repeat-circle.vue"),
  rub: () => import("./rub.vue"),
  serverNetwork: () => import("./server-network.vue"),
  settings: () => import("./settings.vue"),
  severeCold: () => import("./severe-cold.vue"),
  star: () => import("./star.vue"),
  suitcase: () => import("./suitcase.vue"),
  tablerClock: () => import("./tabler-clock.vue"),
  target: () => import("./target.vue"),
  teamLine: () => import("./team-line.vue"),
  team: () => import("./team.vue"),
  telegramC: () => import("./telegram-c.vue"),
  telegramO: () => import("./telegram-o.vue"),
  telegram: () => import("./telegram.vue"),
  tg: () => import("./tg.vue"),
  ticketOne: () => import("./ticket-one.vue"),
  tool: () => import("./tool.vue"),
  toolsWrench: () => import("./tools-wrench.vue"),
  transactionFee: () => import("./transaction-fee.vue"),
  transactionRefundToBalance: () =>
    import("./transaction-refund-to-balance.vue"),
  transactionRefund: () => import("./transaction-refund.vue"),
  transactions: () => import("./transactions.vue"),
  trash: () => import("./trash.vue"),
  undo: () => import("./undo.vue"),
  userBusiness: () => import("./user-business.vue"),
  userGroup: () => import("./user-group.vue"),
  userImages: () => import("./user-images.vue"),
  user: () => import("./user.vue"),
  wallet: () => import("./wallet.vue"),
};

export default Object.entries(icons).reduce<
  Record<string, VuetifyIconComponent>
>((acc, [name, component]) => {
  acc[name] = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    component,
  };
  return acc;
}, {});
